import { CircleNotch } from "phosphor-react";
import { FC, useState } from "react";
import { downloadInvoice } from "../../services/invoice.service";
import { InvoiceGeneratorProps } from "../interfaces/invoice-generator-props";
import Button from "../UI/Button";
import Flex from "../UI/Flex";

interface InvoiceProps {
  data: InvoiceGeneratorProps;
}

const Invoice: FC<InvoiceProps> = (props) => {
  const invoiceId = props.data.invoiceId as string;
  const [isDownloadingPdf, setisDownloadingPdf] = useState(false);

  const resetHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    window.location.reload();
  };

  const downloadHandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    try {
      setisDownloadingPdf(true);
      const response = await downloadInvoice(invoiceId);

      const filename = response.headers["content-disposition"]
        .split(";")[1]
        .split("=")[1];

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename); //or any other extension
      document.body.appendChild(link);
      link.click();

      setisDownloadingPdf(false);
    } catch (err) {
      console.log(err);
      setisDownloadingPdf(false);
    }
  };

  return (
    <Flex
      direction="column"
      gap={20}
      justifyContent="center"
      alignItems="center"
    >
      <span>La factura fue enviada a su correo electronico</span>
      <Button
        onClick={downloadHandler}
        type="button"
        primary
        disabled={isDownloadingPdf}
      >
        {isDownloadingPdf && (
          <CircleNotch size={14}>
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="rotate"
              dur="1s"
              from="0 0 0"
              to="360 0 0"
              repeatCount="indefinite"
            ></animateTransform>
          </CircleNotch>
        )}
        {!isDownloadingPdf && "Descargar PDF"}
      </Button>
      <Button onClick={resetHandler} type="button" disabled={isDownloadingPdf}>
        Generar otro comprobante
      </Button>
    </Flex>
  );
};

export default Invoice;
