import { FC } from "react";
import StyledStep from "./Step.styled";

interface StepProps {
  isActive?: boolean;
  number: number;
  label: string;
}

const Step: FC<StepProps> = ({ isActive, number, label }) => {
  return (
    <StyledStep isActive={isActive}>
      <span>{number}</span>
      <label>{label}</label>
    </StyledStep>
  );
};

export default Step;
