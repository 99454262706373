import styled from "styled-components";

interface InputProps {
  uppercase?: boolean;
  error?: boolean;
}

const Input = styled.input<InputProps>`
  padding: 16px 24px;
  border-radius: 4px;
  border: ${({ error, theme }) =>
    error ? "1px solid " + theme.colors.red : "none"};
  box-shadow: ${({ error }) => (error ? "0 0 2px red" : "none")};
  background-color: #0000000d;
  transition: all 0.3s;
  outline: none;
  text-transform: ${({ uppercase }) => (uppercase ? "uppercase" : "none")};
  font-family: "Poppins", sans-serif;

  &:disabled {
    cursor: not-allowed;
  }
`;

export default Input;
