import { ThemeProvider } from "styled-components";
import InvoiceGenerator from "./components/InvoiceGenerator";
import Logo from "./components/Logo";
import MainContainer from "./components/UI/MainContainer";
import GlobalStyles, { ThemeType } from "./styles/Global";
import LogoImage from "./assets/img/logo.png";
import { BrowserRouter } from "react-router-dom";

function App() {
  const theme: ThemeType = {
    colors: {
      primary: "#8bc53f",
      disabled: "#b2b6bd",
      disabledStep: "#f2f2f2",
      disabledStepText: "#b2b6bd",
      accent: "#3c3",
      red: "#fa5252",
    },
  };
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <MainContainer>
          <Logo>
            <img src={LogoImage} alt="logo" />
          </Logo>
          <InvoiceGenerator />
        </MainContainer>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
