import axios from "../services/axios";
import { Invoice } from "../interfaces/create-invoice.model";

export const searchTicket = (rfc: string, ticket: string, code: string) => {
  return axios.get<any>(`tickets?rfc=${rfc}&ticket=${ticket}&codigo=${code}`);
};

export const generateInvoice = (invoice: Invoice) => {
  return axios.post<any>(`comprobantesweb`, invoice);
};

export const downloadInvoice = (invoiceId: string) => {
  return axios.get<any>(`descargas/comprobante/pdf/${invoiceId}`, {
    responseType: "blob",
  });
};
