import styled from "styled-components";

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 400px) {
    flex-direction: column;
    justify-content: center;
    gap: 14px;
    margin-top: 20px;
  }
`;

export default ButtonContainer;
