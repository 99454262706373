import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { InvoiceGeneratorProps } from "./interfaces/invoice-generator-props";
import Step from "./UI/Step";
import TaxInfo from "./TaxInfo/TaxInfo";
import TicketSearch from "./TicketSearch/TicketSearch";
import Flex from "./UI/Flex";
import Invoice from "./Invoice/Invoice";

const InvoiceGenerator = () => {
  const [searchParams] = useSearchParams();

  const [currentStep, setCurrenStep] = useState(1);
  const [data, setData] = useState<InvoiceGeneratorProps>({
    rfc: searchParams.get("rfc") || "",
    ticket: searchParams.get("ticket") || "",
    code: searchParams.get("codigo") || "",
    email: "",
    fiscalRegime: 0,
    name: "",
    clientId: "",
    saleId: "",
    invoiceId: "",
    postalCode: "",
    paymentForm: 0,
  });

  const handleNextStep = (newData: InvoiceGeneratorProps) => {
    setData((prev) => ({ ...prev, ...newData }));
    setCurrenStep((prev) => prev + 1);
  };

  const handlePrevStep = (newData: InvoiceGeneratorProps) => {
    setData((prev) => ({ ...prev, ...newData }));
    setCurrenStep((prev) => prev - 1);
  };

  const steps = [
    <TicketSearch onNextStep={handleNextStep} data={data} />,
    <TaxInfo
      onNextStep={handleNextStep}
      onPrevStep={handlePrevStep}
      data={data}
    />,
    <Invoice data={data} />,
  ];

  return (
    <Flex direction="column" gap={40}>
      <Flex justifyContent="space-between">
        <Step number={1} label="ticket" isActive={currentStep === 1} />
        <Step number={2} label="datos" isActive={currentStep === 2} />
        <Step number={3} label="factura" isActive={currentStep === 3} />
      </Flex>
      {steps[currentStep - 1]}
    </Flex>
  );
};

export default InvoiceGenerator;
