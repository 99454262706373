import { useFormik } from "formik";
import { CircleNotch } from "phosphor-react";
import { FC, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { searchTicket } from "../../services/invoice.service";
import { InvoiceGeneratorProps } from "../interfaces/invoice-generator-props";
import Button from "../UI/Button";
import ErrorSpan from "../UI/ErrorSpan";
import Flex from "../UI/Flex";
import Input from "../UI/Input";
import validationSchema from "./ticket-search-validation";

interface TicketSearchProps {
  data: InvoiceGeneratorProps;
  onNextStep: (data: any) => void;
}

const TicketSearch: FC<TicketSearchProps> = ({
  data: initialValues,
  onNextStep,
}) => {
  const [isSearching, setIsSearching] = useState(false);
  const MySwal = withReactContent(Swal);

  const submitHandler = async (values: any) => {
    try {
      setIsSearching(true);

      const response = await searchTicket(
        values.rfc,
        values.ticket,
        values.code
      );

      const data = await response.data;

      console.log(data);

      onNextStep({
        ...values,
        saleId: data.id,
        clientId: data.idCliente,
        email: data.correo,
        name: data.nombre,
        fiscalRegime: data.regimenFiscal,
        postalCode: data.cp,
        saleType: data.tipoVenta,

        street: data.calle,
        exteriorNumber: data.noExterior,
        interiorNumber: data.noInterior,
        settlement: data.colonia,
        city: data.ciudad,
        state: data.estado,
        municipality: data.municipio,
      });
      setIsSearching(false);
    } catch (err: any) {
      let message = "No se encontró ningún ticket o éste ya fue facturado";

      if (err.response && err.response.status === 409) {
        message = err.response.data.message;
      }

      await MySwal.fire({
        html: <label>{message}</label>,
        icon: "error",
      });
      setIsSearching(false);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: submitHandler,
    validationSchema: validationSchema,
    validateOnMount: true,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Flex direction="column" gap={30}>
        <Flex direction="column" gap={10}>
          <p>RFC</p>
          <Input
            placeholder="RFC"
            uppercase
            maxLength={13}
            error={formik.touched.rfc && formik.errors.rfc != null}
            {...formik.getFieldProps("rfc")}
          />
          {formik.touched.rfc && formik.errors.rfc && (
            <ErrorSpan>{formik.errors.rfc}</ErrorSpan>
          )}
          <p>Ticket</p>
          <Input
            placeholder="# TICKET"
            {...formik.getFieldProps("ticket")}
            maxLength={10}
            error={formik.touched.ticket && formik.errors.ticket != null}
          />
          {formik.touched.ticket && formik.errors.ticket && (
            <ErrorSpan>{formik.errors.ticket}</ErrorSpan>
          )}
          <p>Code</p>
          <Input
            uppercase
            maxLength={20}
            {...formik.getFieldProps("code")}
            placeholder="CODIGO FACTURACION"
            error={formik.touched.code && formik.errors.code != null}
          />
          {formik.touched.code && formik.errors.code && (
            <ErrorSpan>{formik.errors.code}</ErrorSpan>
          )}
        </Flex>
        <Button
          primary
          disabled={isSearching || !formik.isValid}
          type="submit"
          width={200}
        >
          {isSearching && (
            <CircleNotch size={14}>
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                dur="1s"
                from="0 0 0"
                to="360 0 0"
                repeatCount="indefinite"
              ></animateTransform>
            </CircleNotch>
          )}
          {!isSearching && "SIGUIENTE →"}
        </Button>
      </Flex>
    </form>
  );
};

export default TicketSearch;
