import * as yup from "yup";

const isRequiredMessage = "Este campo es requerido";

export default yup.object().shape({
  name: yup.string().required(isRequiredMessage).min(1, "Mínimo 1 caracter"),
  email: yup
    .string()
    .email("Ingrese un correo válido")
    .required(isRequiredMessage),
  postalCode: yup
    .string()
    .required(isRequiredMessage)
    .matches(new RegExp("^[0-9]{5}$"), "Ingrese un código postal válido"),
});
