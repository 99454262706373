import * as yup from "yup";

const isRequiredMessage = "Este campo es requerido";
const invalidRfcMessage = "Ingrese un RFC válido";
const invalidNumberMessage = "Ingrese un ticket válido";

export default yup.object().shape({
  rfc: yup
    .string()
    .required(isRequiredMessage)
    .matches(
      new RegExp(
        "^([A-ZÑa-zñ]|&){3,4}[0-9]{2}(0[1-9]|1[0-2])([12][0-9]|0[1-9]|3[01])[A-Za-z0-9]{3}$"
      ),
      invalidRfcMessage
    ),
  ticket: yup
    .number()
    .typeError(invalidNumberMessage)
    .required(isRequiredMessage)
    .min(1),
  code: yup.string().required(isRequiredMessage).min(5).max(20),
});
