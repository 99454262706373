import axios from "axios";

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "https://localhost:44353/v1"
      : "https://api.easygas.online/v1",
});

export default instance;
