import styled from "styled-components";

const Logo = styled.div`
  padding: 24px;
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 32px;
  }
`;

export default Logo;
