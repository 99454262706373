import { createGlobalStyle } from "styled-components";

export interface ThemeType {
  colors: {
    primary: string;
    disabled: string;
    disabledStep: string;
    disabledStepText: string;
    accent: string;
    red: string;
  };
}

const GlobalStyles = createGlobalStyle<{ theme: ThemeType }>`

*{
    box-sizing:border-box;
    margin:0;
    padding: 0;
}

body{
    font-family: 'Poppins', sans-serif;
    font-size:12px;
}
`;

export default GlobalStyles;
