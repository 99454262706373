import styled, { ThemeConsumer } from "styled-components";

interface ButtonProps {
  disabled?: boolean;
  width?: number;
  primary?: boolean;
}

const Button = styled.button<ButtonProps>`
  background-color: ${({ theme, disabled, primary }) =>
    disabled ? theme.colors.disabled : primary ? theme.colors.primary : "#fff"};
  padding: 16px 32px;
  border-radius: 100px;
  border: ${({ primary, theme }) =>
    primary ? "none" : "1px solid " + theme.colors.primary};

  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  color: ${({ primary, theme }) =>
    primary ? "#f2f2f2" : theme.colors.primary};
  width: ${({ width }) => (width ? width + "px" : "auto")};
  font-family: "Poppins", sans-serif;
`;

export default Button;
