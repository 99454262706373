import styled from "styled-components";

interface StyledStepProps {
  isActive?: boolean;
}

const StyledStep = styled.div<StyledStepProps>`
  display: flex;
  gap: 10px;
  align-items: center;

  span {
    display: flex;

    justify-content: center;
    align-items: center;

    background-color: ${({ theme, isActive }) =>
      isActive ? theme.colors.primary : theme.colors.disabledStep};
    color: ${({ theme, isActive }) =>
      isActive ? "#fff" : theme.colors.disabledStepText};

    padding: 18px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }

  label {
    text-transform: uppercase;
    color: ${({ theme, isActive }) =>
      isActive ? theme.colors.primary : theme.colors.disabledStepText};
  }

  @media screen and (max-width: 400px) {
    flex-direction: column;
    gap: 8px;
    padding: 0px;
  }
`;

export default StyledStep;
