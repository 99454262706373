import styled from "styled-components";

interface ContainerProps {
  direction?: "row" | "column";
  gap?: number;
  alignItems?: "flex-start" | "center" | "flex-end" | "stretch";
  width?: number | "100%";
  justifyContent?:
    | "stretch"
    | "space-around"
    | "space-between"
    | "center"
    | "flex-end"
    | "flex-start";
  marginBottom?: number;
}

const Flex = styled.div<ContainerProps>`
  display: flex;
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "stretch")};
  flex-direction: ${({ direction }) => (direction ? direction : "row")};
  gap: ${({ gap }) => (gap ? gap + "px" : 0)};
  width: ${({ width }) => (width ? width + "px" : "auto")};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "stretch"};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? marginBottom + "px" : "0"};
`;

export default Flex;
