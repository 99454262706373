import { FormikValues, useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { InvoiceGeneratorProps } from "../interfaces/invoice-generator-props";
import Button from "../UI/Button";
import Flex from "../UI/Flex";
import Input from "../UI/Input";
import Select from "../UI/Select";
import ButtonContainer from "./ButtonContainer";
import validationSchema from "./tax-info-validation";
import { Invoice } from "../../interfaces/create-invoice.model";
import { generateInvoice } from "../../services/invoice.service";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ErrorSpan from "../UI/ErrorSpan";
import { PaymentForm } from "../../enums/payment-form";
import { SaleType } from "../../enums/sale-type";

interface TaxInfoProps {
  data: InvoiceGeneratorProps;
  onNextStep: (data: any) => void;
  onPrevStep: (data: any) => void;
}

const TaxInfo: FC<TaxInfoProps> = ({
  data: initialValues,
  onNextStep,
  onPrevStep,
}) => {
  const [isGeneratingInvoice, setIsGeneratingInvoice] = useState(false);
  // const [isNameEnabled] = useState(!initialValues.clientId);

  const MySwal = withReactContent(Swal);

  const [paymentForms, setPaymentForms] = useState<PaymentForm[]>([]);

  useEffect(() => {
    let list: PaymentForm[] = [];
    switch (initialValues.saleType) {
      case SaleType.Efectivo:
        initialValues.paymentForm = PaymentForm.Efectivo;
        list.push(PaymentForm.Efectivo);
        break;
      case SaleType.Transferencia:
        initialValues.paymentForm = PaymentForm.Transferencia;
        list.push(PaymentForm.Transferencia);
        break;
      case SaleType.Cheque:
        initialValues.paymentForm = PaymentForm.Cheque;
        list.push(PaymentForm.Cheque);
        break;
      case SaleType.TarjetaCredito:
      case SaleType.TarjetaDebito:
        initialValues.paymentForm =
          initialValues.saleType === SaleType.TarjetaDebito
            ? PaymentForm.TarjetaDebito
            : PaymentForm.TarjetaCredito;
        list.push(PaymentForm.TarjetaCredito);
        list.push(PaymentForm.TarjetaDebito);
        break;
      default:
        break;
    }

    setPaymentForms(list);
  }, []);

  const nextStepHandler = async (values: FormikValues) => {
    try {
      setIsGeneratingInvoice(true);

      const invoice: Invoice = {
        codigo: values.code,
        correo: values.email,
        folio: values.ticket,
        formaPago: values.paymentForm,
        regimenFiscal: values.fiscalRegime,
        rfc: values.rfc.toUpperCase(),
        nombre: values.name.toUpperCase(),
        cp: values.postalCode,

        calle: values.street,
        noExterior: values.exteriorNumber,
        noInterior: values.interiorNumber,
        ciudad: values.city,
        colonia: values.settlement,
        municipio: values.municipality,
        estado: values.state,
      };

      const response = await generateInvoice(invoice);

      console.log(response);

      if (response.data.timbrado) {
        onNextStep({
          ...values,
          invoiceId: response.data.id,
        });
      } else {
        await MySwal.fire({
          html: <label>{response.data.mensajeErrorTimbrado}</label>,
          icon: "error",
        });
      }

      setIsGeneratingInvoice(false);
    } catch (err: any) {
      let message = "Error al generar comprobante";

      if (err.response && err.response.status === 409) {
        message = err.response.data.message;
      }

      await MySwal.fire({
        html: <label>{message}</label>,
        icon: "error",
      });
      setIsGeneratingInvoice(false);
    }
  };

  useEffect(() => {}, []);
  const prevStepHandler = (values: FormikValues) => {
    onPrevStep(values);
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: nextStepHandler,
    validationSchema: validationSchema,
    validateOnMount: true,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Flex direction="column" gap={30} marginBottom={20}>
        <Flex direction="column" gap={10}>
          <p>Nombre *</p>
          <Input
            placeholder="Nombre"
            maxLength={250}
            uppercase
            {...formik.getFieldProps("name")}
            error={formik.touched.name && formik.errors.name != null}
          />
          {formik.touched.name && formik.errors.name && (
            <ErrorSpan>{formik.errors.name}</ErrorSpan>
          )}

          <p>Codigo postal*</p>
          <Input
            placeholder="Código Postal"
            {...formik.getFieldProps("postalCode")}
            maxLength={5}
            error={
              formik.touched.postalCode && formik.errors.postalCode != null
            }
          />
          {formik.touched.postalCode && formik.errors.postalCode && (
            <ErrorSpan>{formik.errors.postalCode}</ErrorSpan>
          )}

          <p>Régimen fiscal*</p>
          <Select {...formik.getFieldProps("fiscalRegime")}>
            <option value="601">(601) General de Ley Personas Morales</option>
            <option value="603">
              (603) Personas Morales con Fines no Lucrativos
            </option>
            <option value="605">
              (605) Sueldos y Salarios e Ingresos Asimilados a Salarios
            </option>
            <option value="606">(606) Arrendamiento</option>
            <option value="607">
              (607) Régimen de Enajenación o Adquisición de Bienes
            </option>
            <option value="608">(608) Demás ingresos</option>
            <option value="609">(609) Consolidación</option>
            <option value="610">
              (610) Residentes en el Extranjero sin Establecimiento Permanente
              en México
            </option>
            <option value="611">
              (611) Ingresos por Dividendos (socios y accionistas)
            </option>
            <option value="612">
              (612) Personas Físicas con Actividades Empresariales y
              Profesionales
            </option>

            <option value="614">(614) Ingresos por intereses</option>
            <option value="615">
              (615) Régimen de los ingresos por obtención de premios
            </option>
            <option value="616">(616) Sin obligaciones fiscales</option>
            <option value="620">
              (620) Sociedades Cooperativas de Producción que optan por diferir
              sus ingresos
            </option>
            <option value="621">(621) Incorporación Fiscal</option>
            <option value="622">
              (622) Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras
            </option>
            <option value="623">
              (623) Opcional para Grupos de Sociedades
            </option>
            <option value="624">(624) Coordinados</option>
            <option value="625">
              (625) Régimen de las Actividades Empresariales con ingresos a
              través de Plataformas Tecnológicas
            </option>
            <option value="626">(626) Régimen Simplificado de Confianza</option>
            <option value="628">(628) Hidrocarburos</option>
            <option value="629">
              (629) De los Regímenes Fiscales Preferentes y de las Empresas
              Multinacionales
            </option>
            <option value="630">
              (630) Enajenación de acciones en bolsa de valores
            </option>
          </Select>

          <p>Forma de pago*</p>
          <Select {...formik.getFieldProps("paymentForm")}>
            {paymentForms.includes(PaymentForm.Efectivo) && (
              <option value="1">(01) Efectivo</option>
            )}
            {paymentForms.includes(PaymentForm.Cheque) && (
              <option value="2">(02) Cheque nominativo</option>
            )}
            {paymentForms.includes(PaymentForm.Transferencia) && (
              <option value="3">
                (03) Transferencia electrónica de fondos
              </option>
            )}
            {paymentForms.includes(PaymentForm.TarjetaCredito) && (
              <option value="4">(04) Tarjeta de crédito</option>
            )}
            {paymentForms.includes(PaymentForm.TarjetaDebito) && (
              <option value="28">(28) Tarjeta de débito</option>
            )}
          </Select>

          <p>Correo electronico*</p>
          <Input
            placeholder="ejemplo@dominio.com"
            maxLength={250}
            {...formik.getFieldProps("email")}
            error={formik.touched.email && formik.errors.email != null}
          />
          {formik.touched.email && formik.errors.email && (
            <ErrorSpan>{formik.errors.email}</ErrorSpan>
          )}

          <p>Calle</p>
          <Input
            placeholder="Calle"
            maxLength={100}
            {...formik.getFieldProps("street")}
            error={formik.touched.street && formik.errors.street != null}
          />
          {formik.touched.street && formik.errors.street && (
            <ErrorSpan>{formik.errors.street}</ErrorSpan>
          )}

          <p>No. exterior</p>
          <Input
            placeholder="# Exterior"
            maxLength={20}
            {...formik.getFieldProps("exteriorNumber")}
            error={
              formik.touched.exteriorNumber &&
              formik.errors.exteriorNumber != null
            }
          />
          {formik.touched.exteriorNumber && formik.errors.exteriorNumber && (
            <ErrorSpan>{formik.errors.exteriorNumber}</ErrorSpan>
          )}

          <p>No. interior</p>
          <Input
            placeholder="# Interior"
            maxLength={20}
            {...formik.getFieldProps("interiorNumber")}
            error={
              formik.touched.interiorNumber &&
              formik.errors.interiorNumber != null
            }
          />
          {formik.touched.interiorNumber && formik.errors.interiorNumber && (
            <ErrorSpan>{formik.errors.interiorNumber}</ErrorSpan>
          )}

          <p>Colonia</p>
          <Input
            placeholder="Colonia"
            maxLength={50}
            {...formik.getFieldProps("settlement")}
            error={
              formik.touched.settlement && formik.errors.settlement != null
            }
          />

          <p>Ciudad</p>
          <Input
            placeholder="Ciudad"
            maxLength={50}
            {...formik.getFieldProps("city")}
            error={formik.touched.city && formik.errors.city != null}
          />

          <p>Municipio</p>
          <Input
            placeholder="Municipio"
            maxLength={50}
            {...formik.getFieldProps("municipality")}
            error={
              formik.touched.municipality && formik.errors.municipality != null
            }
          />

          <p>Estado</p>
          <Input
            placeholder="Estado"
            maxLength={50}
            {...formik.getFieldProps("state")}
            error={formik.touched.state && formik.errors.state != null}
          />

          <ButtonContainer>
            <Button
              type="button"
              onClick={prevStepHandler}
              disabled={isGeneratingInvoice}
            >
              REGRESAR
            </Button>
            <Button
              primary
              type="submit"
              disabled={!formik.isValid || isGeneratingInvoice}
            >
              SIGUIENTE →
            </Button>
          </ButtonContainer>
        </Flex>
      </Flex>
    </form>
  );
};

export default TaxInfo;
